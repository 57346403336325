import type { RouteLocationMatch } from "../protocols";
import {
  isCurrentRouteProfile,
  isCurrentRouteWallet,
  isCurrentRouteHelp,
} from "./identifyRoute";
import type { RouteLocationNormalizedLoaded } from "#vue-router";

export const canShowRoute = (
  route: RouteLocationMatch,
  currentRoute: RouteLocationNormalizedLoaded
) => {
  const isIndex = route.name === "index";
  const isPannel = route.name === "painel";
  const mustHidePannel =
    isCurrentRouteProfile(currentRoute) ||
    isCurrentRouteWallet(currentRoute) ||
    isCurrentRouteHelp(currentRoute);

  return !isIndex && !(mustHidePannel && isPannel);
};
