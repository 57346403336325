import type { BreadcrumbItem, RouteLocationMatch } from "../protocols";
import { canShowRoute } from "./canShowRoute";
import { getRouteText } from "./getRouteText";
import type { RouteLocationNormalizedLoaded } from "#vue-router";

export const getBreadcrumbItems = (
  routeRecords: RouteLocationMatch[],
  currentRoute: RouteLocationNormalizedLoaded
) => {
  const filteredRecords = routeRecords.filter((route) => {
    return canShowRoute(route, currentRoute);
  });

  const sorted = filteredRecords.sort((a, b) => {
    return a.path.length - b.path.length;
  });

  return sorted.map((record) => {
    return {
      href: record.path,
      text: getRouteText(record),
    } as BreadcrumbItem;
  });
};
