<script setup lang="ts">
import { useGetCurrentRouteRecords } from "./composables/useGetCurrentRouteRecord";
import { getBreadcrumbItems } from "./utils/getBreadcrumbItems";
import { getBreadcrumbsIcon } from "./utils/getBreadcrumbsIcon";

interface Props {
  mobile?: boolean;
}

defineProps<Props>();

const currentRoute = useRoute();
const getCurrentRouteRecords = useGetCurrentRouteRecords();

const items = computed(() => {
  return getBreadcrumbItems(getCurrentRouteRecords(), currentRoute);
});
const icon = computed(() => getBreadcrumbsIcon(currentRoute));
</script>

<template>
  <div
    class="flex gap-2 text-gray-chateau items-center capitalize"
    :class="{ 'xl:hidden': mobile }"
  >
    <img :src="icon" class="w-6 h-6" />
    <template v-for="(item, index) in items" :key="item.href">
      <span
        v-if="index > 0"
        :class="{
          'text-primary-200': index === items.length - 1,
        }"
      >
        /
      </span>
      <NuxtLink
        :to="item.href"
        class="underline"
        :class="{
          'text-primary-200': index === items.length - 1,
        }"
      >
        {{ item.text }}
      </NuxtLink>
    </template>
  </div>
</template>
