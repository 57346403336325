import type { RouteLocationMatch } from "../protocols";

export const getRouteText = (route: RouteLocationMatch) => {
  const fallback = "-";

  if (route.meta.breadcrumb) {
    return route.meta.breadcrumb;
  }

  const pathSegments = route.path.split("/").filter(Boolean);
  if (pathSegments.length === 0) {
    return fallback;
  }

  const lastPathSegment =
    pathSegments[pathSegments.length - 1]?.replace(/-/g, " ") || fallback;
  return lastPathSegment;
};
